import { Component, Watch } from 'vue-property-decorator';
import { OsTable, OsQueryPlan } from '@/components';
import { OsTableColumn, OsTableOption, RowOperation } from '@/components/os-table/os-table';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import { ColorChangeSampleList, ColorChangeSampleQuery } from '@/resource/model';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import {
  messageError,
  translation,
  dateFormat,
  downloadFileByBlob,
  messageErrors,
  debounce,
  showWarningConfirm
} from '@/utils';
import {
  PageQueryPlanEnum,
  PrepressChangeColorCraftsEnum,
  PrepressLayoutCraftsEnum,
  PrepressSampleStatusEnum,
  PrepressTypeEnum
} from '@/resource/enum';
import { customerService, colorChangeSampleService } from '@/api';
import { mixins } from 'vue-class-component';
import { PagingMixin } from '@/mixins/paging';
import PrepressTaskDetails from '../prepress-task-details/prepress-task-details.vue';
import { Message } from 'element-ui';
import Redeploy from '../redeploy/redeploy.vue';
import { CustomColumnMixin } from '@/mixins/custom-column';
import { NormalSelectOptions } from '@/resource/model/common';
import { SortOptions } from '@/api/base';
import ElImageViewer from 'element-ui/packages/image/src/image-viewer';
import { QueryPlanOperationOption } from '@/components/os-query-plan/os-query-plan';
@Component({
  components: { PrepressTaskDetails, Redeploy, ElImageViewer }
})
export default class ColorChangeSample extends mixins(PagingMixin, CustomColumnMixin) {
  public type = PrepressTypeEnum;
  public tableOption: OsTableOption<ColorChangeSampleList> = {
    loading: false,
    data: [],
    fit: true,
    defaultSort: { order: 'ascending', prop: 'itemCode' }
  };

  /**
   * 默认的表格列配置
   */
  public defaultColumnOptions: Array<OsTableColumn<ColorChangeSampleList>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true,
      fixed: true
    },
    {
      prop: 'itemCode',
      label: 'prepress.itemCode',
      minWidth: '230px',
      sortable: 'custom',
      showOverflowTooltip: true,
      fixed: true
    },
    {
      prop: 'round',
      label: 'prepress.round',
      minWidth: '100px',
      showOverflowTooltip: true,
      fixed: true
    },

    {
      prop: 'customerName',
      label: 'project.customer',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'status',
      label: 'projectProduct.status',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'platformProductName',
      label: 'prepress.platformProduct',
      showOverflowTooltip: true,
      minWidth: '180px'
    },

    {
      prop: 'visibleHeight',
      label: 'prepress.visibleSize',
      showOverflowTooltip: true,
      minWidth: '160px',
      formatter: (row: Object): string => {
        return `${(row as ColorChangeSampleList).visibleWidth} × ${(row as ColorChangeSampleList).visibleHeight}`;
      }
    },
    {
      prop: 'finishHeight',
      label: 'prepress.finishSize',
      showOverflowTooltip: true,
      minWidth: '160px',
      formatter: (row: Object): string => {
        return `${(row as ColorChangeSampleList).finishWidth} × ${(row as ColorChangeSampleList).finishHeight}`;
      }
    },
    {
      prop: 'count',
      label: 'prepressTask.count',
      showOverflowTooltip: true,
      minWidth: '100px'
    },
    {
      prop: 'screenArea',
      label: 'billing.totalArea',
      minWidth: '120px',
      showOverflowTooltip: true
    },
    {
      prop: 'screenRate',
      label: 'prepressTask.screenRate',
      minWidth: '120px',
      showOverflowTooltip: true
    },

    {
      prop: 'customerProductName',
      label: 'prepress.customerProduct',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'backendCrafts',
      label: 'prepress.backendCrafts',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'requiredDeliveryTime',
      label: 'prepress.requiredDeliveryTime',
      minWidth: '180px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        return dateFormat((row as ColorChangeSampleList).requiredDeliveryTime, 'YYYY-MM-DD HH:mm');
      }
    },
    {
      prop: 'prepressCrafts',
      label: 'projectProduct.prepressCrafts',
      minWidth: '180px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        if (!(row as ColorChangeSampleList).prepressCrafts) {
          return '--';
        }
        if ((row as ColorChangeSampleList).itemType === PrepressTypeEnum.changeColor) {
          return translation(
            `prepressChangeColorCrafts.${PrepressChangeColorCraftsEnum[(row as ColorChangeSampleList).prepressCrafts]}`
          );
        }
        return translation(
          `prepressLayoutCrafts.${PrepressLayoutCraftsEnum[(row as ColorChangeSampleList).prepressCrafts]}`
        );
      }
    },
    {
      prop: 'prepressPicture',
      label: 'prepress.prepressPicture',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'prepressRemark',
      label: 'prepress.prepressRemark',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'remark',
      label: 'prepress.remark',
      minWidth: '200px',
      showOverflowTooltip: true
    },

    {
      prop: 'createUserName',
      label: 'prepress.createUserName',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'createTime',
      label: 'project.createTime',
      minWidth: '180px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        return dateFormat((row as ColorChangeSampleList).createTime);
      }
    }
  ];

  /**
   * table上方的表格操作配置
   */
  public operationOptions: Array<OperationOption> = [
    {
      type: 'primary',
      slot: 'start',
      label: 'prepressAction.submitProofing',
      operationType: 'submit',
      icon: 'el-icon-check',
      disabled: true,
      tip: '',
      permissionCode: 'color:change:manage:submit',
      handleClick: this.submit
    },
    {
      type: 'primary',
      slot: 'start',
      label: 'prepressAction.cancelSubmitProofing',
      operationType: 'cancel',
      icon: 'el-icon-close',
      disabled: true,
      tip: '',
      permissionCode: 'color:change:manage:cancel',
      handleClick: this.cancel
    },
    {
      type: 'primary',
      slot: 'start',
      label: 'button.delete',
      operationType: 'delete',
      icon: 'el-icon-delete',
      disabled: true,
      permissionCode: 'color:change:manage:delete',
      handleClick: this.batchDelete
    },
    {
      type: 'primary',
      slot: 'start',
      label: 'button.export',
      operationType: 'export',
      icon: 'el-icon-download',
      plain: true,
      permissionCode: 'color:change:manage:export',
      handleClick: this.export
    }
  ];

  /**
   * table上方的条件查询配置
   */
  public queryItemsOption: Array<OsQueryItemOption<ColorChangeSampleQuery>> = [
    {
      type: 'Input',
      field: 'itemCode',
      label: 'prepress.code',
      option: {
        placeholder: 'common.inputKeyWord'
      }
    },
    {
      type: 'Input',
      field: 'platformProductName',
      label: 'prepress.platformProduct',
      option: {
        placeholder: 'consumed.inputPlatformProduct'
      }
    },
    {
      type: 'Select',
      field: 'statusList',
      label: 'projectProduct.status',
      option: {
        placeholder: 'projectProduct.status',
        multiple: true,
        clearable: true
      },
      optionData: (): NormalSelectOptions => {
        return colorChangeSampleService.getPrepressSampleStatus();
      }
    },
    {
      type: 'Input',
      field: 'prepressPicture',
      label: 'prepress.prepressPicture',
      option: {
        placeholder: 'prepress.inputPrepressPicture'
      }
    },

    {
      type: 'DateRangePicker',
      field: 'requiredDeliveryTime',
      label: 'prepressTask.deliveryTime',
      option: {
        rangeSeparator: '~'
      }
    },
    {
      type: 'Select',
      field: 'customerId',
      label: 'project.customer',
      option: {
        placeholder: 'project.selectCustomer',
        filterable: true
      },
      optionData: []
    },
    {
      type: 'Input',
      field: 'createUserName',
      label: 'prepress.createUserName',
      option: {
        placeholder: 'project.selectCreator'
      }
    },
    {
      type: 'DateRangePicker',
      field: 'orderCreateTime',
      label: 'prepress.createDate',
      option: {
        rangeSeparator: '~',
        pickerOptions: {
          disabledDate(callbackDateStr: string): boolean {
            const callbackDateTime = new Date(callbackDateStr).getTime();
            const today = new Date();
            const currentDateTime = today.getTime();
            return callbackDateTime >= currentDateTime;
          }
        }
      }
    }
  ];
  /**
   * table行的操作配置
   */
  public rowOperationOptions: RowOperation<ColorChangeSampleList> = {
    fixed: 'right',
    width: '180px',
    operations: [
      {
        operationType: 'edit',
        type: 'text',
        label: 'button.edit',
        icon: 'el-icon-edit',
        permissionCode: 'color:change:manage:edit',
        dynamicHidden: (rowData: ColorChangeSampleList): boolean => {
          return rowData.status !== PrepressSampleStatusEnum.waitSubmit;
        },
        handleClick: (rowData: ColorChangeSampleList): void => {
          this.edit(rowData);
        }
      },
      {
        operationType: 'copy',
        type: 'text',
        label: 'button.copy',
        icon: 'el-icon-document-copy',
        permissionCode: 'color:change:manage:save',
        handleClick: (rowData: ColorChangeSampleList): void => {
          this.copy(rowData);
        }
      }
    ]
  };
  /**
   * 控制详情dialog显隐
   */
  public detailsVisible = false;

  /**
   * 印前单据主键
   */
  public prepressId: number | null = null;

  public selectedRows: Array<ColorChangeSampleList> = [];

  public redeployVisible = false;

  public redeployLoading = false;

  public selectedIds: Array<number> = [];

  /**
   * 展示确稿预览文件
   */
  // public showThumbnails = false;

  /**
   * 要预览的图片列表
   */
  // public previewImgList: Array<string> = [];

  /**
   * 页面标识
   */
  public code: number = PageQueryPlanEnum.colorChangeSample;
  /**
   * 查询方案编辑按钮
   */
  public queryPlanEndOption: Array<QueryPlanOperationOption> = [
    {
      id: 0,
      type: 'primary',
      slot: 'end',
      label: 'button.save',
      permissionCode: 'system:query:template:save',
      handleClick: (): void => {
        (this.$refs.OsQueryPlan as OsQueryPlan).dialogOpened();
      }
    },
    {
      id: 1,
      type: 'text',
      slot: 'end',
      label: 'button.edit',
      permissionCode: 'system:query:template:delete',
      handleClick: (): void => {
        (this.$refs.OsQueryPlan as OsQueryPlan).editDialogOpened();
      }
    }
  ];

  private queryForm: Partial<ColorChangeSampleQuery> = {};
  private defaultQueryForm: Partial<ColorChangeSampleQuery> = {
    createUserName: '',
    customerId: undefined,
    itemCode: '',
    orderCreateTime: [],
    platformProductName: '',
    prepressPicture: '',
    statusList: [],
    requiredDeliveryTime: []
  };

  private sortOptions: SortOptions<ColorChangeSampleList> = this.tableOption.defaultSort!;

  public get area(): string {
    return this.selectedRows
      .reduce((res, curr) => res + (curr.finishWidth / 1000) * (curr.finishHeight / 1000) * curr.count, 0)
      .toFixed(3);
  }
  public activated(): void {
    this.queryClick();
  }
  public mounted(): void {
    this.queryClick();
  }
  public created(): void {
    this.initColumns(this.defaultColumnOptions, 'color-change-sample');
    this.getCustomers();
  }

  public reloadData(): void {
    this.paging.currentPage = 1;
    (this.$refs.colorChangeSample as OsTable).clearSelection();
    this.selectedRows = [];
    // 重置查询方案
    (this.$refs.OsQueryPlan as OsQueryPlan).id = 0;
    this.loadData();
  }

  public pagingData(): void {
    this.loadData();
  }

  @debounce()
  public queryClick(): void {
    if (this.$route.query.queryPlanName) {
      this.paging.currentPage = 1;
      (this.$refs.OsQueryPlan as OsQueryPlan).selectQueryPlan(this.$route.query.queryPlanName as string);
      return;
    }
    this.loadData();
  }

  /**
   * 执行查询方案
   * @param json
   */
  public queryList(json: string): void {
    this.paging.currentPage = 1;
    Object.assign(this.queryForm, this.defaultQueryForm);
    Object.assign(this.queryForm, JSON.parse(json));
    this.loadData();
  }

  public handleSortChange(sortOptions: SortOptions<ColorChangeSampleList>): void {
    this.sortOptions = sortOptions;
    this.reloadData();
  }

  public linkToDetails(row: ColorChangeSampleList): void {
    const id = row.id.toString();
    this.$router.push({
      path: 'color-change-sample-details',
      query: {
        id: id
      }
    });
  }
  public edit(row: ColorChangeSampleList): void {
    const id = row.id.toString();
    this.$router.push({
      path: 'add-change-color-sample',
      query: {
        id: id,
        type: 'edit'
      }
    });
  }
  public copy(row: ColorChangeSampleList): void {
    const id = row.id.toString();
    this.$router.push({
      path: 'add-change-color-sample',
      query: {
        id: id,
        type: 'copy'
      }
    });
  }

  public handleSelectionChange(selectedData: Array<ColorChangeSampleList>): void {
    this.selectedRows = selectedData;
  }

  public getStatusClass(sample: ColorChangeSampleList): string {
    switch (sample.status) {
      case PrepressSampleStatusEnum.waitSubmit:
        return 'warning-dot';
      case PrepressSampleStatusEnum.inProduction:
        return 'primary-dot';
      // case PrepressSampleStatusEnum.waitSampled:
      //   return 'warning-dot';
      // case PrepressSampleStatusEnum.waitComplete:
      //   return 'warning-dot';
      case PrepressSampleStatusEnum.completed:
        return 'success-dot';
      default:
        return 'info-dot';
    }
  }

  public getStatusName(sample: ColorChangeSampleList): string {
    if (!sample.status) {
      return translation('common.unKnownStatus');
    }
    return `prepressSampleStatus.${PrepressSampleStatusEnum[sample.status]}`;
  }

  /**
   * 提交打样
   */
  public submit(): void {
    const errorList: Array<string> = this.selectedRows
      .filter(x => x.status !== PrepressSampleStatusEnum.waitSubmit)
      .map(x => x.itemCode);
    if (errorList.length > 0) {
      Message.warning(
        `${translation('prepressTask.unMatchedCompleteStatus')}:${errorList.map(item => item).join(',')}`
      );
      return;
    }
    const ids = this.selectedRows.map(x => x.id);
    this.tableOption.loading = true;
    colorChangeSampleService
      .submit(ids)
      .then(() => {
        this.reloadData();
        Message.success(translation('operationRes.operationSuccess'));
      })
      .catch(error => {
        messageErrors(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }
  /**
   * 取消打样
   */
  public cancel(): void {
    const errorList: Array<string> = this.selectedRows
      .filter(x => x.status !== PrepressSampleStatusEnum.inProduction)
      .map(x => x.itemCode);
    if (errorList.length > 0) {
      Message.warning(
        `${translation('prepressTask.unMatchedCompleteStatus')}:${errorList.map(item => item).join(',')}`
      );
      return;
    }
    const ids = this.selectedRows.map(x => x.id);
    this.tableOption.loading = true;
    colorChangeSampleService
      .cancel(ids)
      .then(() => {
        this.reloadData();
        Message.success(translation('operationRes.operationSuccess'));
      })
      .catch(error => {
        messageErrors(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }

  private async batchDelete(): Promise<void> {
    try {
      await showWarningConfirm(translation('tip.confirmDelete'));
      this.tableOption.loading = true;
      await colorChangeSampleService.batchDelete(this.selectedRows.map(x => x.id));
      this.reloadData();
      Message.success(translation('operationRes.deleteSuccess'));
    } catch (error) {
      if (error === 'cancel') {
        Message.info(translation('operationRes.cancelDelete'));
        return;
      }
      messageErrors(error);
    } finally {
      this.tableOption.loading = false;
    }
  }

  // /**
  //  * 确稿图片预览
  //  * @param fileName
  //  * @returns
  //  */
  // public async thumbnailsPreview(fileName: string): Promise<void> {
  //   try {
  //     await minioService.init();
  //     const url = await minioService.getDownLoadPath(fileName);
  //     if (getFileExtension(fileName) === 'pdf') {
  //       const newWindow = window.open();
  //       newWindow?.document.write(
  //         `<iframe width="100%" height="100%" src="${url}" frameborder="0" allowfullscreen></iframe>`
  //       );
  //       return;
  //     }
  //     // 先清空
  //     this.previewImgList = [];
  //     this.previewImgList.push(url);
  //     this.showThumbnails = true;
  //   } catch (error) {
  //     messageError(error as any);
  //   }
  // }

  private loadData(): void {
    this.tableOption.loading = true;
    colorChangeSampleService
      .getList(this.queryForm, this.paging, this.sortOptions)
      .then(res => {
        this.tableOption.data = res.data.map(x => {
          x.screenArea = ((x.finishWidth / 1000) * (x.finishHeight / 1000) * x.count).toFixed(6);
          x.screenRate = (x.visibleWidth / x.visibleHeight).toFixed(3);
          x.itemType = PrepressTypeEnum.changeColor;
          return x;
        });
        this.totalData = res.total;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }

  private async export(): Promise<void> {
    this.tableOption.loading = true;
    try {
      const ids = this.selectedRows.map(x => x.id);
      const blob = await colorChangeSampleService.export(this.queryForm, ids);
      downloadFileByBlob(`${translation('prepressTask.exportChangeColorSample')}_${dateFormat(new Date())}.xlsx`, blob);
    } catch (error) {
      messageError(error);
    } finally {
      this.tableOption.loading = false;
    }
  }

  private getCustomers(): void {
    customerService
      .getAllUsingCustomer()
      .then(res => {
        const customerQuery = this.queryItemsOption.find(x => x.field === 'customerId');
        customerQuery!.optionData = res.map(x => {
          return { label: x.companyName, value: x.id };
        });
      })
      .catch(error => {
        messageError(error);
      });
  }

  @Watch('queryForm.orderCreateTime')
  private handleCreateTimeChanged(value: Array<string>): void {
    if (!value || value.length === 0) {
      this.queryForm.createStartTime = undefined;
      this.queryForm.createEndTime = undefined;
      return;
    }
    if (value && value.length === 2) {
      this.queryForm.createStartTime = dateFormat(value[0]);
      this.queryForm.createEndTime = dateFormat(value[1], 'YYYY-MM-DD') + ' 23:59:59';
    }
  }

  @Watch('queryForm.requiredDeliveryTime')
  private handleRequiredDeliveryTimeChanged(value: Array<string>): void {
    if (!value || value.length === 0) {
      this.queryForm.requiredDeliveryStartTime = undefined;
      this.queryForm.requiredDeliveryEndTime = undefined;
      return;
    }
    if (value.length === 2) {
      this.queryForm.requiredDeliveryStartTime = dateFormat(value[0]);
      this.queryForm.requiredDeliveryEndTime = dateFormat(value[1], 'YYYY-MM-DD') + ' 23:59:59';
    }
  }

  @Watch('selectedRows')
  private handleSelectedChanged(value: Array<ColorChangeSampleList>): void {
    this.operationOptions.forEach(x => {
      if (x.operationType !== 'export') {
        x.disabled = value.length === 0;
      }
    });
  }
}
